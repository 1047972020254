import request from '@/utils/request'

export default {
  checkCode(queryFrom) {
    return request({
      url: `/exchange/checkCode`,
      method: 'post',
      data: queryFrom
    })
  },
  addCodeInfo(info) {
    return request({
      url: `/exchange/addCodeInfo`,
      method: 'post',
      data: info
    })
  },
  code() {
    return request({
      url: `/exchange/code`,
      method: 'get'
    })
  }
}
