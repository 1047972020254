<template>
  <div class="info">
    <p style="margin: 20px 0 60px 0; text-align: center; font-size: 20px; font-weight: bold">菲佑宁兑换</p>
    <div v-if="showFormFlag">
      <p style="margin: 20px 0">8位兑换码</p>
      <el-input v-model="checkForm.code" placeholder="请输入8位兑换码" maxlength="8"></el-input>
      <p style="margin: 20px 0">验证码</p>
      <div class="login-code">
        <div style="width: 65%; height: 50px; float: left"><el-input v-model="checkForm.captcha" placeholder="请输入验证码"></el-input></div>
        <div class="login-code-img"><img :src="codeImg" @click="getCode" style="height: 40px" /></div>
      </div>
      <el-button type="primary" style="margin: 20px 0; width: 100%" @click="checkBtn">确认兑换</el-button>
    </div>
    <div v-else v-loading="listLoading">
      <el-tag>兑换码：{{ checkForm.code }}</el-tag>
      <el-form label-position="top" label-width="80px" ref="eInfo" :model="eInfo" style="margin-top: 20px" :rules="rules">
        <el-form-item label="姓名" prop="eName">
          <el-input v-model="eInfo.eName"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="ePhone">
          <el-input v-model="eInfo.ePhone" oninput="value=value.replace(/[^\d]/g,'')" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="eAddress">
          <el-input type="textarea" v-model="eInfo.eAddress"></el-input>
        </el-form-item>
        <el-form-item label="年龄" prop="eAge">
          <el-input-number v-model="eInfo.eAge" :max="100" :min="1"></el-input-number>
        </el-form-item>
      </el-form>
      <el-button type="primary" style="margin: 20px 0; width: 100%" @click="exchangeInfo('eInfo')">提交信息</el-button>
    </div>
  </div>
</template>
<script>
import exchangeApi from '@/api/exchange.js'
import JsEncrypt from 'jsencrypt'

export default {
  data() {
    return {
      showFormFlag: true,
      eInfo: {
        id: 0,
        eName: '',
        ePhone: '',
        eAddress: '',
        eAge: ''
      },
      checkForm: {
        code: '',
        captcha: '',
        uuid: ''
      },
      codeImg: '',
      listLoading: false,
      jsencrypt: new JsEncrypt(),
      publicKey: '',
      rules: {
        eName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        ePhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '手机号不对', trigger: 'blur' }
        ],
        eAddress: [{ required: true, message: '请输入邮寄地址', trigger: 'blur' }],
        eAge: [{ required: true, message: '请输入年龄', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.getCode()
  },
  methods: {
    getCode() {
      exchangeApi.code().then((res) => {
        this.codeImg = 'data:image/gif;base64,' + res.data.img
        this.checkForm.uuid = res.data.uuid
        this.publicKey = res.data.publicKey
        this.jsencrypt.setPublicKey(this.publicKey)
      })
    },
    checkBtn() {
      if (this.checkForm.code == '' || this.checkForm.captcha == '') {
        return
      }
      var that = this
      exchangeApi.checkCode(this.checkForm).then((res) => {
        console.log('1234567890')
        if (res.data.success) {
          that.showFormFlag = false
          that.eInfo.id = res.data.data.id
          this.$message({
            message: '兑换码正确，请填写邮寄地址',
            type: 'success'
          })
        } else {
          this.$message.error(res.data.data.msg)
        }
        that.getCode()
      })
    },
    exchangeInfo(eInfo) {
      this.$refs[eInfo].validate((valid) => {
        if (valid) {
          var info = {
            id: this.eInfo.id,
            name: this.eInfo.eName,
            code: this.checkForm.code,
            phone: this.eInfo.ePhone,
            address: this.eInfo.eAddress,
            age: this.eInfo.eAge
          }
          var that = this
          that.listLoading = true
          exchangeApi.addCodeInfo(info).then((res) => {
            this.$confirm('填写成功', '提示', {
              confirmButtonText: '确定',
              showCancelButton: false,
              type: 'success'
            })
              .then(() => {
                that.eInfo = {}
                that.checkForm = {}
                that.showFormFlag = true
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '网络出错，请重试'
                })
              })
            that.listLoading = false
          })
        }
      })
    }
  }
}
</script>
<style scoped>
.info {
  margin: 40px 20px 20px;
}
.login-code-img {
  float: right;
}
</style>
